import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export function Navbar() {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? 'bg-blue-700' : '';
  };

  return (
    <nav className="bg-blue-600 text-white shadow-lg">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <Link to="/" className="text-xl font-bold">
            Smart Billing
          </Link>
          
          <div className="flex space-x-4">
            <Link
              to="/"
              className={`px-3 py-2 rounded-md hover:bg-blue-700 ${isActive('/')}`}
            >
              Home
            </Link>
            <Link
              to="/menu"
              className={`px-3 py-2 rounded-md hover:bg-blue-700 ${isActive('/menu')}`}
            >
              Menu Management
            </Link>
            <Link
              to="/settings"
              className={`px-3 py-2 rounded-md hover:bg-blue-700 ${isActive('/settings')}`}
            >
              Settings
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}