import React from 'react';
import { MenuManagement } from '../components/MenuManagement';

export function MenuManagementPage() {
  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold text-center mb-8">
        Menu Management
      </h1>
      <div className="card">
        <MenuManagement />
      </div>
    </div>
  );
}