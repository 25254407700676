import { Edit, Leaf, Plus, Trash2, UtensilsIcon, Wheat } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

const TYPE_CONFIG = {
  restaurant: {
    types: [
      { value: 'Vegetarian', label: 'Vegetarian', icon: <Leaf className="w-5 h-5" />, color: 'text-green-600', bgColor: 'bg-green-50' },
      { value: 'Non-Vegetarian', label: 'Non-Vegetarian', icon: <UtensilsIcon className="w-5 h-5" />, color: 'text-red-600', bgColor: 'bg-red-50' },
      { value: 'Vegan', label: 'Vegan', icon: <Wheat className="w-5 h-5" />, color: 'text-emerald-600', bgColor: 'bg-emerald-50' }
    ],
    itemLabel: 'Menu Item'
  }
};

export function MenuManagement() {
  const [settings, setSettings] = useState({
    currency: { symbol: '$' } // Default settings
  });
  const [menuItems, setMenuItems] = useState([]);
  const [newItem, setNewItem] = useState({
    name: '',
    price: '',
    type: 'Vegetarian',
    category: ''
  });
  const [editingItem, setEditingItem] = useState(null);

  useEffect(() => {
    try {
      // Load settings
      const savedSettings = localStorage.getItem('businessSettings');
      if (savedSettings) {
        setSettings(prevSettings => ({
          ...prevSettings,
          ...JSON.parse(savedSettings)
        }));
      }

      // Load menu items
      const savedMenu = localStorage.getItem('menuItems');
      if (savedMenu) {
        setMenuItems(JSON.parse(savedMenu));
      }
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error('Error loading saved data');
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewItem(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newItem.name.trim()) {
      toast.error('Item name is required');
      return;
    }
    if (!newItem.price || parseFloat(newItem.price) <= 0) {
      toast.error('Please enter a valid price');
      return;
    }

    if (editingItem) {
      // Update existing item
      const updatedMenu = menuItems.map(item =>
        item.id === editingItem.id ? { ...newItem, id: editingItem.id } : item
      );
      setMenuItems(updatedMenu);
      localStorage.setItem('menuItems', JSON.stringify(updatedMenu));
      toast.success('Item updated successfully');
    } else {
      // Add new item
      const newMenuItem = {
        ...newItem,
        id: Date.now()
      };
      const updatedMenu = [...menuItems, newMenuItem];
      setMenuItems(updatedMenu);
      localStorage.setItem('menuItems', JSON.stringify(updatedMenu));
      toast.success('Item added successfully');
    }

    // Reset form
    setNewItem({
      name: '',
      price: '',
      type: 'Vegetarian',
      category: ''
    });
    setEditingItem(null);
  };

  const handleEdit = (item) => {
    setNewItem(item);
    setEditingItem(item);
  };

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      const updatedMenu = menuItems.filter(item => item.id !== id);
      setMenuItems(updatedMenu);
      localStorage.setItem('menuItems', JSON.stringify(updatedMenu));
      toast.success('Item deleted successfully');
    }
  };

  const formatPrice = (price) => {
    return `${settings?.currency?.symbol || '$'}${parseFloat(price).toFixed(2)}`;
  };

  if (!settings) return null;

  const getTypeDetails = (typeValue) => {
    return TYPE_CONFIG.restaurant.types.find(t => t.value === typeValue) || TYPE_CONFIG.restaurant.types[0];
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">Menu Management</h2>

        {/* Add/Edit Item Form */}
        <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <input
            type="text"
            name="name"
            value={newItem.name}
            onChange={handleChange}
            placeholder="Item Name"
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
          />

          <input
            type="number"
            name="price"
            value={newItem.price}
            onChange={handleChange}
            placeholder="Price"
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
            step="0.01"
            min="0"
          />

          <select
            name="type"
            value={newItem.type}
            onChange={handleChange}
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
          >
            {TYPE_CONFIG.restaurant.types.map(type => (
              <option key={type.value} value={type.value}>{type.label}</option>
            ))}
          </select>

          <input
            type="text"
            name="category"
            value={newItem.category}
            onChange={handleChange}
            placeholder="Category (optional)"
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
          />

          <div className="md:col-span-4">
            <button
              type="submit"
              className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 inline-flex items-center"
            >
              <Plus className="w-5 h-5 mr-2" />
              {editingItem ? 'Update Item' : 'Add Item'}
            </button>
          </div>
        </form>
      </div>

      {/* Items Table */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Type</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Category</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Price</th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {menuItems.map(item => {
              const typeDetails = getTypeDetails(item.type);
              return (
                <tr key={item.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4">{item.name}</td>
                  <td className="px-6 py-4">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${typeDetails.color} ${typeDetails.bgColor}`}>
                      {typeDetails.icon}
                      <span className="ml-1">{typeDetails.label}</span>
                    </span>
                  </td>
                  <td className="px-6 py-4">{item.category || '-'}</td>
                  <td className="px-6 py-4 text-right">{formatPrice(item.price)}</td>
                  <td className="px-6 py-4 text-center flex space-x-2 justify-center">
                    <button onClick={() => handleEdit(item)} className="text-blue-600 hover:text-blue-900">
                      <Edit className="w-5 h-5" />
                    </button>
                    <button onClick={() => handleDelete(item.id)} className="text-red-600 hover:text-red-900">
                      <Trash2 className="w-5 h-5" />
                    </button>
                  </td>
                </tr>
              );
            })}
            {menuItems.length === 0 && (
              <tr>
                <td colSpan={5} className="px-6 py-8 text-center text-gray-500">
                  No items added yet. Add your first menu item using the form above.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
