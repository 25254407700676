import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

const CURRENCIES = [
  { code: 'USD', symbol: '$', name: 'US Dollar' },
  { code: 'EUR', symbol: '€', name: 'Euro' },
  { code: 'GBP', symbol: '£', name: 'British Pound' },
  { code: 'INR', symbol: '₹', name: 'Indian Rupee' },
  { code: 'CNY', symbol: '¥', name: 'Chinese Yuan' },
  { code: 'JPY', symbol: '¥', name: 'Japanese Yen' },
  { code: 'NPR', symbol: 'रू', name: 'Nepalese Rupee' },
  // Add more currencies as needed
];

export function BusinessInfo() {
  const [businessInfo, setBusinessInfo] = useState({
    name: '',
    counter: '',
    phone: '',
    gst: '',
    address: '',
    currency: { code: 'USD', symbol: '$', name: 'US Dollar' } // Default currency
  });

  useEffect(() => {
    const savedInfo = localStorage.getItem('businessInfo');
    if (savedInfo) {
      setBusinessInfo(JSON.parse(savedInfo));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBusinessInfo(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCurrencyChange = (e) => {
    const selectedCurrency = CURRENCIES.find(curr => curr.code === e.target.value);
    setBusinessInfo(prev => ({
      ...prev,
      currency: selectedCurrency
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!businessInfo.name.trim()) {
      toast.error('Business name is required');
      return;
    }
    localStorage.setItem('businessInfo', JSON.stringify(businessInfo));
    toast.success('Business information saved successfully');
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-6">Business Information</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700 mb-2">Business Name *</label>
            <input
              type="text"
              name="name"
              value={businessInfo.name}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2">Counter Number</label>
            <input
              type="text"
              name="counter"
              value={businessInfo.counter}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2">Phone Number</label>
            <input
              type="tel"
              name="phone"
              value={businessInfo.phone}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2">GST Number</label>
            <input
              type="text"
              name="gst"
              value={businessInfo.gst}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2">Currency</label>
            <select
              value={businessInfo.currency?.code || 'USD'}
              onChange={handleCurrencyChange}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
            >
              {CURRENCIES.map(curr => (
                <option key={curr.code} value={curr.code}>
                  {curr.name} ({curr.symbol})
                </option>
              ))}
            </select>
            <p className="mt-1 text-sm text-gray-500">
              Selected currency: {businessInfo.currency?.symbol} ({businessInfo.currency?.code})
            </p>
          </div>
        </div>
        <div>
          <label className="block text-gray-700 mb-2">Address</label>
          <textarea
            name="address"
            value={businessInfo.address}
            onChange={handleChange}
            rows={3}
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Save Business Info
        </button>
      </form>
    </div>
  );
}