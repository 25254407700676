import { Fish, Leaf, Plus, Printer, RefreshCw, Trash2, Wheat } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

const FOOD_TYPE_ICONS = {
  'Vegetarian': <Leaf className="w-5 h-5 text-green-600" />,
  'Non-Vegetarian': <Fish className="w-5 h-5 text-red-600" />,
  'Vegan': <Wheat className="w-5 h-5 text-emerald-600" />
};

export function HomePage() {
  const [selectedItem, setSelectedItem] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [currentBill, setCurrentBill] = useState([]);
  const [tax, setTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [menuItems, setMenuItems] = useState([]);
  const [tokenNumber, setTokenNumber] = useState(() => {
    const savedToken = localStorage.getItem('lastTokenNumber');
    return savedToken ? parseInt(savedToken) + 1 : 1;
  });

  useEffect(() => {
    const savedMenu = localStorage.getItem('menuItems');
    if (savedMenu) {
      setMenuItems(JSON.parse(savedMenu));
    }
  }, []);

  const formatCurrency = (amount) => {
    const businessInfo = JSON.parse(localStorage.getItem('businessInfo') || '{}');
    const currency = businessInfo.currency || { symbol: '$', code: 'USD' };
    return `${currency.symbol}${amount.toFixed(2)}`;
  };

  const handleAddToBill = () => {
    if (!selectedItem) {
      toast.error('Please select an item');
      return;
    }

    const item = menuItems.find(i => i.id === parseInt(selectedItem));
    if (!item) return;

    const existingItemIndex = currentBill.findIndex(i => i.itemId === item.id);

    if (existingItemIndex !== -1) {
      // Update quantity if item already exists
      const updatedBill = [...currentBill];
      updatedBill[existingItemIndex].quantity += parseInt(quantity);
      updatedBill[existingItemIndex].total = 
        updatedBill[existingItemIndex].quantity * updatedBill[existingItemIndex].price;
      setCurrentBill(updatedBill);
    } else {
      // Add new item to bill
      const newBillItem = {
        id: Date.now(),
        itemId: item.id,
        name: item.name,
        foodType: item.foodType,
        price: parseFloat(item.price),
        quantity: parseInt(quantity),
        total: parseFloat(item.price) * parseInt(quantity)
      };
      setCurrentBill(prev => [...prev, newBillItem]);
    }

    setSelectedItem('');
    setQuantity(1);
    toast.success('Item added to bill');
  };

  const handleRemoveItem = (id) => {
    setCurrentBill(prev => prev.filter(item => item.id !== id));
    toast.success('Item removed from bill');
  };

  const calculateTotal = () => {
    const subtotal = currentBill.reduce((sum, item) => sum + item.total, 0);
    const taxAmount = (subtotal * tax) / 100;
    const discountAmount = (subtotal * discount) / 100;
    return {
      subtotal,
      tax: taxAmount,
      discount: discountAmount,
      total: subtotal + taxAmount - discountAmount
    };
  };

  const resetBill = () => {
    if (currentBill.length > 0) {
      if (window.confirm('Are you sure you want to reset the current bill?')) {
        setCurrentBill([]);
        setTax(0);
        setDiscount(0);
        setSelectedItem('');
        setQuantity(1);
        toast.success('Bill reset successfully');
      }
    }
  };
  
  const handlePrint = () => {
    if (currentBill.length === 0) {
      toast.error('Please add items to bill first');
      return;
    }

    const totals = calculateTotal();
    const businessInfo = JSON.parse(localStorage.getItem('businessInfo') || '{}');
    if (!businessInfo.name || businessInfo.name.trim() === '') {
        toast.error('Business name is required. Please set it in the settings.');
        // Redirect to the settings page
        window.location.href = '/settings';
        return;
      }
    const currentTime = new Date().toLocaleTimeString();
    const currentDate = new Date().toLocaleDateString();
    
    const copyTypes = ['CUSTOMER COPY', 'CASH COUNTER COPY', 'KITCHEN COPY'];
    
    const printWindow = window.open('', '_blank');
    const printContent = copyTypes.map(copyType => `
      <div class="bill-section" style="page-break-after: always;">
        <div style="text-align: center; font-family: 'Courier New', monospace; width: 80mm; margin: 0 auto;">
          <h2 style="margin: 5px 0; font-size: 16px;">${businessInfo.name}</h2>
          <p style="margin: 3px 0; font-size: 12px;">${businessInfo.address || ''}</p>
          <p style="margin: 3px 0; font-size: 12px;">Phone: ${businessInfo.phone || ''}</p>
          <p style="margin: 3px 0; font-size: 12px;">GST: ${businessInfo.gst || ''}</p>
          <p style="margin: 3px 0; font-size: 12px;">Token #: ${tokenNumber}</p>
          <p style="margin: 3px 0; font-size: 12px;">Date: ${currentDate}</p>
          <p style="margin: 3px 0; font-size: 12px;">Time: ${currentTime}</p>
          <p style="font-weight: bold; margin: 5px 0; font-size: 14px;">${copyType}</p>
          <div style="border-top: 1px dashed #000; margin: 10px 0;"></div>
          
          ${currentBill.map(item => `
            <div style="display: flex; justify-content: space-between; margin: 5px 0; font-size: 12px;">
              <span style="text-align: left;">${item.name} (${item.foodType})</span>
              <span style="text-align: right;">${item.quantity} x ${formatCurrency(item.price)} = ${formatCurrency(item.total)}</span>
            </div>
          `).join('')}
          
          <div style="border-top: 1px dashed #000; margin: 10px 0;"></div>
          
          <div style="text-align: right; font-size: 12px;">
            <p style="margin: 3px 0;">Subtotal: ${formatCurrency(totals.subtotal)}</p>
            ${tax > 0 ? `<p style="margin: 3px 0;">Tax (${tax}%): ${formatCurrency(totals.tax)}</p>` : ''}
            ${discount > 0 ? `<p style="margin: 3px 0;">Discount (${discount}%): ${formatCurrency(totals.discount)}</p>` : ''}
            <h3 style="margin: 5px 0; font-size: 14px;">Total: ${formatCurrency(totals.total)}</h3>
          </div>
          
          <div style="border-top: 1px dashed #000; margin: 10px 0;"></div>
          <p style="text-align: center; margin: 5px 0; font-size: 12px;">Thank you for your business!</p>
          <p style="text-align: center; margin: 5px 0; font-size: 12px;">Visit Again!</p>
          <div style="margin-bottom: 30px;"></div>
        </div>
      </div>
    `).join('');

    printWindow.document.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <title>Bill #${tokenNumber}</title>
          <style>
            @page { 
              size: 80mm auto; 
              margin: 0; 
            }
            @media print {
              .bill-section { page-break-after: always; }
              body { margin: 0; padding: 10px; }
            }
          </style>
        </head>
        <body>
          ${printContent}
          <script>
            window.onafterprint = () => {
              window.close();
              window.opener.location.href = '/';
            };
            window.print();
          </script>
        </body>
      </html>
    `);

    // Save token number and reset bill
    localStorage.setItem('lastTokenNumber', tokenNumber.toString());
    setTokenNumber(prev => prev + 1);
    setCurrentBill([]);
    setTax(0);
    setDiscount(0);
    
    printWindow.document.close();
  };

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold text-center mb-8">Billing Dashboard</h1>
      
      <div className="bg-white rounded-lg shadow-lg p-6">
        {/* Add Item Section */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
          <select
            value={selectedItem}
            onChange={(e) => setSelectedItem(e.target.value)}
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none col-span-2"
          >
            <option value="">Select Item</option>
            {menuItems.map(item => (
              <option key={item.id} value={item.id}>
                {item.name} - {formatCurrency(parseFloat(item.price))} ({item.foodType})
              </option>
            ))}
          </select>
          
          <input
            type="number"
            min="1"
            value={quantity}
            onChange={(e) => setQuantity(parseInt(e.target.value) || 1)}
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
          />
          
          <button
            onClick={handleAddToBill}
            className="flex items-center justify-center bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          >
            <Plus className="w-5 h-5 mr-2" />
            Add to Bill
          </button>
        </div>

        {/* Current Bill */}
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-4">Current Bill</h3>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Item</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Type</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Price</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Qty</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Total</th>
                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Action</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentBill.map(item => (
                <tr key={item.id}>
                  <td className="px-6 py-4">{item.name}</td>
                  <td className="px-6 py-4">
                    <div className="flex items-center gap-2">
                      {FOOD_TYPE_ICONS[item.foodType]}
                      <span>{item.foodType}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right">{formatCurrency(item.price)}</td>
                  <td className="px-6 py-4 text-right">{item.quantity}</td>
                  <td className="px-6 py-4 text-right">{formatCurrency(item.total)}</td>
                  <td className="px-6 py-4 text-center">
                    <button
                      onClick={() => handleRemoveItem(item.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="w-5 h-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Bill Totals */}
          <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Tax Rate (%)
              </label>
              <input
                type="number"
                min="0"
                step="0.1"
                value={tax}
                onChange={(e) => setTax(parseFloat(e.target.value) || 0)}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Discount (%)
              </label>
              <input
                type="number"
                min="0"
                step="0.1"
                value={discount}
                onChange={(e) => setDiscount(parseFloat(e.target.value) || 0)}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Total Amount
              </label>
              <input
                type="text"
                value={formatCurrency(calculateTotal().total)}
                readOnly
                className="w-full p-2 border rounded bg-gray-50"
              />
            </div>
          </div>

          {/* Actions */}
          <div className="mt-6 flex justify-end space-x-4">
            <button
              onClick={resetBill}
              className="flex items-center px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
            >
              <RefreshCw className="w-5 h-5 mr-2" />
              Reset Bill
            </button>
            <button
              onClick={handlePrint}
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              <Printer className="w-5 h-5 mr-2" />
              Print Bill
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  
}

