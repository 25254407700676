import React from 'react';
import { BusinessInfo } from '../components/BusinessInfo';

export function SettingsPage() {
  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold text-center mb-8">
        Settings
      </h1>
      <div className="card">
        <BusinessInfo />
      </div>
    </div>
  );
}